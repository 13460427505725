body {
  background-color: #eeeeee;
}

/* Estilos para todas las etiquetas "p" */
p {
  font-size: 1rem;
  line-height: 1.5;
  text-align: justify;
}

/* Ajustar tamaño de fuente para diferentes tamaños de pantalla */
@media screen and (min-width: 768px) {
  p {
    font-size: 1.2rem;
  }
}

@media screen and (min-width: 1024px) {
  p {
    font-size: 1.5rem;
  }
}

.seccion-1 {
  background-color: #fff;
}

.seccion-3 {
  background-color: #f9fafb;
  padding-right: 5vw;
  padding-bottom: 8vh;
}

.seccion-4 {
  background-color: #f9fafb;
  padding-right: 5vw;
  padding-left: 5vw;
  padding-bottom: 8vh;
  margin-top: 1vh;
}

.titulo-libro {
  font-size: 3rem;
  font-family: 'Permanent Marker', cursive;
  text-align: center;
  margin-bottom: 3vh;
}

.carousel-caption {
  position: relative;
  align-items: center;
  justify-content: center;
  height: 50vh;
  right: 0px;
  bottom: 0px;
  padding-top: 17vh;
  padding-bottom: 0px;
  left: 0px;
}

.carousel-item h3,
h1 {
  position: relative;
  color: #052248;
  text-align: center;
}

.autor-frase {
  position: relative;
  color: #052248;
  text-align: center;
}

.carousel-item h1 {
  text-transform: uppercase;
}

.carousel-item img {
}

.img-libro {
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
}

.img-libro-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 992px) {
  .img-libro {
    max-width: 80%;
  }

  .carousel-caption {
    padding-top: 42vh;
  }
}

@media (max-width: 991px) {
  .img-libro {
    display: none;
  }
}

.navbar-page {
  background-color: #ffc500;
}

.titulo-pagina {
  display: flex;
  justify-content: center;
  align-items: center;
}

.terms {
  position: relative;
  height: 60vh;
  overflow-y: scroll;
}

.terms p {
  font-size: 12px;
}
.terms h3 {
  font-size: 14px;
}

.terms h2 {
  font-size: 14 !important;
}

.contenedor-texto-dosbotones {
  text-align: center;
}

.texto-bonito {
  font-size: 30px;
  text-align: justify;
  color: black;
}

.fondo-contenedor {
  background-color: #f3f3f3;
  margin-left: 0px !important;
  margin-right: 0px !important;
  position: relative;
  padding-top: 8vh;
  padding-bottom: 8vh;
  padding-right: 4vw;
  padding-left: 4vw;
}

.fondo-contenedor h1,
.fondo-contenedor h2 {
  text-align: center;
}
.img-container {
  position: relative;
  /* width: 200px; */

  display: flex;
  overflow: hidden;
  justify-content: center;
  margin-bottom: 20px;
  margin-top: 20px;
}

.img-autor {
  max-width: 100%;
  width: 100%;
  height: auto;
  object-fit: cover;
}

@media (max-width: 991.98px) {
  .img-autor {
    max-height: 50vh;
    object-fit: contain;
  }

  .carousel-item {
    height: 50vh;
  }
}
@media (min-width: 992px) {
  .img-autor {
    max-width: 70%;
  }

  .carousel-item {
    height: 90vh;
  }
}

.img-portada {
  width: 100%;
}

.img-autor-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.nombre-autor {
  font-size: 3rem;
  text-align: center;
  margin-bottom: 3vh;
  margin-top: 3vh;
}

.card-body-libro {
  text-align: center;
}

.boton-comprar {
  margin-top: 3vh;
  width: 100%;
}

.qr-image {
  width: 129px;
}

.frases-carousel {
  height: fit-content;
}

.footer {
  padding-top: 5vh;
  background-color: #052248;
  color: #fff;
  padding-bottom: 10px;
  padding-left: 2vw;
  padding-right: 2vw;
}
